import { assets } from '@/config/assets-links';

type GuidelinePage = {
  title: string;
  description1: string;
  description2?: string;
  description3?: string;
  showDownloadApp?: boolean;
  mediaType: 'image' | 'video';
  mediaS3Key: string;
};

export type GuidelinesConfig = {
  translationNamespace: 'home' | 'magicEraser';
  lineHeight?: number;
  pages: ReadonlyArray<GuidelinePage>;
};

export const ERASER_GUIDELINES: GuidelinesConfig = {
  translationNamespace: 'magicEraser',
  pages: [
    {
      title: 'welcomeDialog.page1.title',
      description1: 'welcomeDialog.page1.description1',
      description2: 'welcomeDialog.page1.description2',
      description3: 'welcomeDialog.page1.description3',
      mediaType: 'video',
      mediaS3Key: assets.cdnVideoAssets.magicEraserStep1,
    },
    {
      title: 'welcomeDialog.page2.title',
      description1: 'welcomeDialog.page2.description1',
      description2: 'welcomeDialog.page2.description2',
      description3: 'welcomeDialog.page2.description3',
      mediaType: 'video',
      mediaS3Key: assets.cdnVideoAssets.magicEraserStep2,
    },
    {
      title: 'welcomeDialog.page3.title',
      description1: 'welcomeDialog.page3.description1',
      description2: 'welcomeDialog.page3.description2',
      mediaType: 'video',
      mediaS3Key: assets.cdnVideoAssets.magicEraserStep3,
    },
  ],
};

export const ONBOARDING_GUIDELINES: GuidelinesConfig = {
  translationNamespace: 'home',
  lineHeight: 1.75,
  pages: [
    {
      title: 'onboardingDialog.page1.title',
      description1: 'onboardingDialog.page1.description1',
      description2: 'onboardingDialog.page1.description2',
      mediaType: 'image',
      mediaS3Key: assets.cdnImageAssets.onboardingModal.step1,
    },
    {
      title: 'onboardingDialog.page2.title',
      description1: 'onboardingDialog.page2.description1',
      showDownloadApp: true,
      mediaType: 'image',
      mediaS3Key: assets.cdnImageAssets.onboardingModal.step2,
    },
    {
      title: 'onboardingDialog.page3.title',
      description1: 'onboardingDialog.page3.description1',
      mediaType: 'image',
      mediaS3Key: assets.cdnImageAssets.onboardingModal.step3,
    },
  ],
};
