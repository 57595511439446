import { useEffect, useState } from 'react';
import { parseDateAfterFilter, parseDateBeforeFilter } from '@/utils/date';
import { deleteAlbum, useAlbums } from '@/api/meero-suite/album';
import { useDebouncedSearch } from '@/hooks/use-debounced-search';
import { AlbumCollectionMember } from '@/types/Album/AlbumCollection';
import { useAuth0Credentials } from '@/hooks/use-auth0-credentials';
import { SortCriteriaMediaType, SortOrderType } from '@/types/Sort';
import { MediaStatus } from '@/types/Media/Media';
import { ALBUMS_PER_PAGE } from '../constants';

export interface AlbumFilters {
  uuid?: string;
  search?: string;
  createdAfter?: string;
  createdBefore?: string;
  status?: MediaStatus[];
  sortCriteria?: SortCriteriaMediaType;
  sortOrder?: SortOrderType;
  qualityScoreTotalScoreMin?: number;
  qualityScoreTotalScoreMax?: number;
}

interface useLibraryAlbumsResponse {
  albums: Array<AlbumCollectionMember>;
  totalAlbums: number | undefined;
  isLastPage: boolean;
  deleteAlbumAndRevalidateList: (uuid: string) => Promise<void>;
  isLoading: boolean;
  isValidating: boolean;
  isError: boolean;
  isEmptyAlbum: boolean;
  isFilterActive: boolean;
}

export const useLibraryAlbums = (
  page: number,
  search?: string | undefined,
  filters?: AlbumFilters
): useLibraryAlbumsResponse => {
  const { workspaceId, getTokenAsync } = useAuth0Credentials();
  const [totalAlbums, setTotalAlbums] = useState<number | undefined>();
  const searchDebounced = useDebouncedSearch(search);

  const { data, mutate, isValidating, isLoading, error } = useAlbums({
    page,
    itemsPerPage: ALBUMS_PER_PAGE,
    search: searchDebounced,
    createdAfter: parseDateAfterFilter(filters?.createdAfter),
    createdBefore: parseDateBeforeFilter(filters?.createdBefore),
    ...(filters?.sortCriteria !== undefined &&
      filters.sortOrder !== undefined && {
        [filters.sortCriteria]: filters.sortOrder,
      }),
  });

  const albums = data?.['hydra:member'] ?? [];
  const hydraCount = data?.['hydra:totalItems'];
  const isLastPage = data?.['hydra:view']['hydra:next'] === undefined;

  // Prevents totalAlbums to reset to undefined when the data is fetched
  useEffect(() => {
    if (hydraCount !== undefined) {
      setTotalAlbums(hydraCount);
    }
  }, [hydraCount]);

  const isFilterActive =
    filters?.search !== undefined ||
    filters?.createdBefore !== undefined ||
    filters?.createdAfter !== undefined;

  const deleteAlbumAndRevalidateList = async (uuid: string) => {
    try {
      await deleteAlbum(uuid, getTokenAsync, workspaceId);
      await mutate((prevData) => {
        if (!prevData) return prevData;

        return {
          ...prevData,
          'hydra:member': prevData['hydra:member'].filter(
            (album) => album.uuid !== uuid
          ),
        };
      });

      return Promise.resolve();
    } catch (err) {
      console.error(err);

      return Promise.reject();
    }
  };

  return {
    albums,
    totalAlbums,
    deleteAlbumAndRevalidateList,
    isLoading,
    isValidating,
    isLastPage,
    isError: error !== undefined,
    isEmptyAlbum: !isLoading && error === undefined && albums.length === 0,
    isFilterActive,
  };
};
