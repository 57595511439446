import { externalLinks } from '@/config/assets-links';

export function isMobile(userAgent: string | null) {
  if (userAgent === null) {
    return false;
  }

  const isAndroid = /android/i.test(userAgent);
  const isIphone = /iphone/i.test(userAgent);

  return isAndroid || isIphone;
}

/**
 * Returns the deep link url when the user is on mobile user agent (android / iphone), null otherwise
 */
export function getMobileDeepLinkUrl(userAgent: string | null) {
  if (isMobile(userAgent)) {
    return externalLinks.mobileDeepLinksUrl;
  }

  return null;
}

export type UserOsDevice = 'android' | 'iphone';

export function detectMobileOs(userAgent: string | null): UserOsDevice | null {
  if (userAgent === null) {
    return null;
  }

  if (/android/i.test(userAgent)) return 'android';
  if (/iphone/i.test(userAgent)) return 'iphone';

  return null;
}
