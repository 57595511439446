import { useState } from 'react';
import { Trans, useTranslation } from 'next-i18next';
import {
  BetaChip,
  StyledDialog,
  StyledDialogText,
  StyledMobileStepper,
  StyledTwicImg,
  StyledTwicVideo,
} from '@/components/common/GuidelinesDialog/GuidelineDialog.styles';
import {
  DialogActions,
  DialogMedia,
} from '@/components/common/Dialog/Dialog.styles';
import { ButtonMeero } from '@/components/common/ButtonMeero/ButtonMeero';
import { Box, Fade, Typography } from '@/components/common';
import { isMobile } from '@/utils/app-store-redirection';
import { AppQRCode } from '../AppQRCodeAndLinks/AppQRCodeAndLinks';
import type { GuidelinesConfig } from './guidelines-config';

type GuidelinesDialogProps = {
  guidelinesConfig: GuidelinesConfig;
  isOpen: boolean;
  onConfirm: () => void;
  confirmButtonVariant: 'letsStart' | 'letsTry';
};

export const GuidelinesDialog = ({
  guidelinesConfig,
  isOpen,
  onConfirm,
  confirmButtonVariant = 'letsStart',
}: GuidelinesDialogProps) => {
  const { t } = useTranslation('common');
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const { lineHeight, translationNamespace, pages } = guidelinesConfig;

  const handleNextButtonClick = () => {
    if (currentPageIndex < pages.length - 1) {
      setCurrentPageIndex((val) => val + 1);

      return;
    }
    onConfirm();
  };

  const currentPage = pages[currentPageIndex];

  const showDownloadLinks =
    currentPage.showDownloadApp === true && !isMobile(navigator.userAgent);

  return (
    <StyledDialog
      open={isOpen}
      transitionDuration={{ enter: 1500, exit: 500 }}
      TransitionProps={{ onEnter: () => setCurrentPageIndex(0) }}
      maxWidth={false}
    >
      <DialogMedia>
        {currentPage.mediaType === 'video' ? (
          <StyledTwicVideo src={currentPage.mediaS3Key} ratio="none" />
        ) : (
          <StyledTwicImg
            src={currentPage.mediaS3Key}
            ratio="none"
            preTransform="quality=100"
          />
        )}
      </DialogMedia>
      <Box
        display="flex"
        flexDirection="column"
        padding={5.5}
        justifyContent="left"
        maxWidth="53ch"
        rowGap={3}
      >
        <Fade key={currentPageIndex} appear in timeout={1000}>
          <StyledDialogText>
            <GuidelinePage
              translationNamespace={translationNamespace}
              page={pages[currentPageIndex]}
              lineHeight={lineHeight}
            />
            {showDownloadLinks && (
              <Box marginTop={3}>
                <AppQRCode />
              </Box>
            )}
          </StyledDialogText>
        </Fade>
        <StyledMobileStepper
          activeStep={currentPageIndex}
          steps={pages.length}
          backButton={null}
          nextButton={null}
          position="static"
        />
        <DialogActions>
          {currentPageIndex !== 0 && (
            <ButtonMeero
              type="button"
              onClick={() => setCurrentPageIndex((val) => val - 1)}
              $type="secondary"
              $size="small"
            >
              {t('guidelinesModal.back')}
            </ButtonMeero>
          )}
          <ButtonMeero
            type="button"
            onClick={handleNextButtonClick}
            $type="primary"
            $size="small"
          >
            {t(
              `guidelinesModal.${
                currentPageIndex < pages.length - 1
                  ? 'next'
                  : confirmButtonVariant
              }`
            )}
          </ButtonMeero>
        </DialogActions>
      </Box>
    </StyledDialog>
  );
};

interface GuidelinePageProps {
  translationNamespace: string;
  page: GuidelinesConfig['pages'][number];
  lineHeight?: number;
}

const GuidelinePage = ({
  translationNamespace,
  page,
  lineHeight,
}: GuidelinePageProps) => {
  const { t } = useTranslation(translationNamespace);

  return (
    <>
      <Typography
        variant="h4"
        textAlign="left"
        fontWeight="bold"
        marginBottom={1}
      >
        <Trans
          i18nKey={t(page.title)}
          components={{
            1: <BetaChip />,
          }}
        />
      </Typography>
      <Typography align="left" color="textSecondary" lineHeight={lineHeight}>
        <Trans i18nKey={t(page.description1)} />
      </Typography>
      {page.description2 !== undefined && (
        <Typography align="left" color="textSecondary" lineHeight={lineHeight}>
          <Trans i18nKey={t(page.description2)} />
        </Typography>
      )}
      {page.description3 !== undefined && (
        <Typography align="left" color="textSecondary" lineHeight={lineHeight}>
          <Trans i18nKey={t(page.description3)} />
        </Typography>
      )}
    </>
  );
};
