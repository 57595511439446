import { TwicImg } from '@twicpics/components/react';
import Image from 'next/image';
import { Box } from '@/components/common';
import { assets, externalLinks } from '@/config/assets-links';
import { detectMobileOs, UserOsDevice } from '@/utils/app-store-redirection';

export function AppQRCode({ className }: Partial<HTMLDivElement>) {
  return (
    <Box
      component="div"
      display="flex"
      gap={2}
      alignItems="center"
      className={className}
    >
      <Box width={125} display="flex">
        <Image
          src="/images/AppStoreQrCode.svg"
          width={125}
          height={125}
          objectFit="contain"
          alt="empty list"
          priority
          unoptimized
        />
      </Box>
    </Box>
  );
}

interface ResponsiveAppDownloadLinksProps {
  userOsDevice: UserOsDevice;
}
export function ResponsiveAppDownloadLinks({
  userOsDevice,
}: ResponsiveAppDownloadLinksProps) {
  return (
    <Box display="flex" justifyContent="start" gap={1}>
      {userOsDevice === 'android' && (
        <Box width="60%">
          <a
            href={externalLinks.propershotPlayStoreUrl}
            target="_blank"
            rel="noreferrer"
          >
            <TwicImg
              src={assets.cdnImageAssets.androidBadge}
              alt="Get it on Google Play"
              mode="cover"
              ratio={109 / 32}
              preTransform="quality=100/format=png "
            />
          </a>
        </Box>
      )}
      {userOsDevice === 'iphone' && (
        <Box width="60%">
          <a
            href={externalLinks.properShotAppStoreUrl}
            target="_blank"
            rel="noreferrer"
          >
            <TwicImg
              src={assets.cdnImageAssets.iosBadge}
              alt="Download on the App Store"
              mode="cover"
              ratio={109 / 32}
              preTransform="quality=100/format=png "
            />
          </a>
        </Box>
      )}
    </Box>
  );
}

export function AppDownloadLinks() {
  const mobileOs = detectMobileOs(navigator.userAgent);
  if (mobileOs !== null) {
    return <ResponsiveAppDownloadLinks userOsDevice={mobileOs} />;
  }

  return (
    <Box display="flex" justifyContent="start" gap={1}>
      <Box width={130}>
        <a
          href={externalLinks.propershotPlayStoreUrl}
          target="_blank"
          rel="noreferrer"
        >
          <TwicImg
            src={assets.cdnImageAssets.androidBadge}
            alt="Get it on Google Play"
            mode="cover"
            ratio={109 / 32}
            preTransform="quality=100/format=png "
          />
        </a>
      </Box>
      <Box width={130}>
        <a
          href={externalLinks.properShotAppStoreUrl}
          target="_blank"
          rel="noreferrer"
        >
          <TwicImg
            src={assets.cdnImageAssets.iosBadge}
            alt="Download on the App Store"
            mode="cover"
            ratio={109 / 32}
            preTransform="quality=100/format=png "
          />
        </a>
      </Box>
    </Box>
  );
}
