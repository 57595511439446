import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { Home } from '@/components/pages/Home/Home';
import { withPageContext } from '@/context/PageContext';
import { Page } from '@/types/Page';
import { withWorkspaceAuthRequired } from '@/lib/withWorkspaceAuthRequired';

export default withWorkspaceAuthRequired(withPageContext(Home, Page.home));

export const getStaticProps = async ({ locale }: { locale: string }) => ({
  props: {
    ...(await serverSideTranslations(locale, [
      'common',
      'navigationBar',
      'header',
      'home',
      'importPhotos',
      'confirmDialog',
      'enhancementToggleButton',
      'filters',
      'mediaViewer',
      'albumCard',
    ])),
  },
});
