import { Box, Skeleton } from '@/components/common';
import { AlbumGridCardSkeleton } from '@/components/common/GridCardSkeleton/GridCardSkeleton';
import { TextSkeleton } from '@/components/common/TextSkeleton/TextSkeleton';
import {
  FeatureDemoCard,
  FeatureDemoContainer,
  HomeGridLayout,
  HomepageContainer,
} from './Home.styles';

type HomeSkeletonProps = {
  isloading: boolean;
  albumCoverToDisplay: number;
};

export const HomeSkeleton = ({ albumCoverToDisplay }: HomeSkeletonProps) => {
  return (
    <HomepageContainer>
      <Box width="100%">
        <Skeleton
          animation="wave"
          variant="rectangular"
          width="300px"
          height="35px"
          style={{ borderRadius: '8px' }}
        />
      </Box>
      <Box display="flex" flexDirection="column" rowGap={3}>
        <Box display="flex" width="100%">
          <Box display="flex" alignItems="center" gap={1}>
            <Skeleton
              animation="wave"
              variant="rounded"
              width={18}
              height={18}
            />
            <TextSkeleton width="100px" height="30px" />
          </Box>
          <Box display="flex" justifyContent="end" gap={1} width="100%">
            <Skeleton
              animation="wave"
              variant="rectangular"
              width="90px"
              height="100%"
              style={{ borderRadius: '8px' }}
            />
            <Skeleton
              animation="wave"
              variant="rectangular"
              width="180px"
              height="38px"
              style={{ borderRadius: '8px' }}
            />
          </Box>
        </Box>
        <HomeGridLayout>
          {Array.from({ length: albumCoverToDisplay + 1 }).map((_, index) => (
            <AlbumGridCardSkeleton key={index} />
          ))}
        </HomeGridLayout>
      </Box>
      <Box display="flex" flexDirection="column" gap={1} width="100%">
        <Box display="flex" alignItems="center" gap={1}>
          <Skeleton animation="wave" variant="rounded" width={18} height={18} />
          <TextSkeleton width="150px" height="30px" />
        </Box>
        <FeatureDemoContainer>
          {Array.from({ length: 3 }).map((_, index) => {
            return (
              <FeatureDemoCard key={index}>
                <Box>
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width={56}
                    height={56}
                    style={{ borderRadius: '8px' }}
                  />
                </Box>
                <TextSkeleton width={200} height={30} />
              </FeatureDemoCard>
            );
          })}
        </FeatureDemoContainer>
      </Box>
      <Skeleton
        animation="wave"
        variant="rectangular"
        width="100%"
        height={168}
        style={{
          borderRadius: 12,
          padding: 24,
        }}
      />
    </HomepageContainer>
  );
};
