import { SvgIconProps } from '@mui/material/SvgIcon';
import { SvgIcon } from '@/components/common';

export default function MagicEraserIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.54319 21.3482H12.7563L21.6706 12.434C22.4516 11.6529 22.4516 10.3866 21.6706 9.60554L16.7208 4.65579C15.9398 3.87475 14.6734 3.87475 13.8924 4.65579L3.2858 15.2624C2.50475 16.0434 2.50475 17.3098 3.2858 18.0908L6.54319 21.3482ZM4.70001 16.6766L7.37161 19.3482H11.9279L14.9531 16.3231L10.0033 11.3733L4.70001 16.6766Z"
        fill={props.color}
      />
      <path
        d="M16.6366 19.3482L14.6366 21.3482H21.6366V19.3482H16.6366Z"
        fill={props.color}
      />
      <path
        d="M7.62001 8L8.56001 5.93L10.62 5L8.56001 4.07L7.62001 2L6.70001 4.07L4.62001 5L6.70001 5.93L7.62001 8Z"
        fill={props.color}
      />
      <path
        d="M3.70001 11.875L4.20001 9.87505L6.20001 9.37505L4.20001 8.87505L3.70001 6.87505L3.20001 8.87505L1.20001 9.37505L3.20001 9.87505L3.70001 11.875Z"
        fill={props.color}
      />
    </SvgIcon>
  );
}
